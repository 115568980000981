import {Injectable, NgZone} from '@angular/core';
import {AlertController, LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';

import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';

import {Observable} from 'rxjs';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router, NavigationExtras} from '@angular/router';
import { Preferences } from '@capacitor/preferences';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public isLoading = false;
  public token = '';
  public email = '';
  public uuid;
  public deviceInfo;
  public connected = true;

  public dataStore = []; // TODO Speichern für offline Daten
  public dataToSave = [];

  public APP_URL_ENDPOINT = 'https://www.kuenzi-leckortung.ch/app/api/';
  public MEDIA_FOLDER_NAME = 'my_media';
  public FILE_FOLDER_NAME = 'my_files';
  public IMAGES_FOLDER_NAME = 'my_images';
  public FILE_URL = this.APP_URL_ENDPOINT + 'upload/viewfiles.php';

  constructor(
      public http: HttpClient,
      private router: Router,
      public platform: Platform,
      public loadingCtrl: LoadingController,
      public alertCtrl: AlertController,
      public toastController: ToastController,
      public modalCtr: ModalController,
  ) {

    Device.getId().then((val) => {
      this.uuid = val.uuid;
    });

    Device.getInfo().then((val) => {
      this.deviceInfo = val;
    });

    Network.addListener('networkStatusChange', status => {
      this.connected = status.connected;
      if(this.connected)
        this.send_data(null);
    });
  }

  async getStorageValue(key){
    return await Preferences.get({
      key: key
    });
  }

  async setStorageValue(key, val){
    await Preferences.set({
      key: key,
      value: val,
    });
  }

  getFileUrl(filepath)
  {
    return this.FILE_URL + '?filepath='+encodeURIComponent(filepath)+'&t=' + encodeURIComponent(this.token);
  }
  
  save_data(record, callback){
    if (typeof this.dataToSave === 'undefined')
      this.dataToSave = new Array();
    this.dataToSave.push(record);

    if(typeof this.dataStore[record.form] === 'undefined')
      this.dataStore[record.form] = [];

    this.dataStore[record.form].push(record);

    this.setStorageValue('dataToSave', JSON.stringify(this.dataToSave)).then(() => {
      this.send_data(callback);
    });
  }

  send_data(callback){
    this.getStorageValue('dataToSave').then((dataToSaveJSON) => {

      let dataToSave = JSON.parse(dataToSaveJSON.value);

      if(this.connected) {
        this.getServerData({
          'controller': 'save_data',
          'action': 'save',
          'data': dataToSave,
        }, false, true).subscribe(data => {
          if (data.success == 1) {
            this.dataToSave = [];
            if(typeof callback !== 'undefined' && callback != null)
              callback();
          } else {
            alert('Error');
          }
          this.dissmissLoader();
        });
      } else {
        if(typeof callback !== 'undefined' && callback != null)
          callback();
      }
    });
  }

  get_data(form, record){
    if(this.connected){

      return this.getServerData({
        'controller': 'get_data',
        'table': form,
        'record': record,
      }, false, true);

    } else {

      return new Observable((observer) => {
        const data = [];
        data['success'] = 1;
        if(record != '') {
          for(let i = 0; i < this.dataStore[form]; i++) {
            if(this.dataStore[form]['id'] == record) {
              data['data'] = this.dataStore[form][i];
              break;
            }
          }
        } else
          data['data'] = this.dataStore[form];
        observer.next(data);
      });

    }
  }

  editItemData(item, data, route) {
    const navigationExtras: NavigationExtras = {
      state: {
        item: item,
        data: data
      }
    };
    this.router.navigate([route], navigationExtras);
  }
  editItem(item, route) {
    const navigationExtras: NavigationExtras = {
      state: {
        item: item,
      }
    };
    this.router.navigate([route], navigationExtras);
  }
  saveItemData(item, data, form, route) {
    item.form = form;
    this.save_data(item, () => {
      const navigationExtras: NavigationExtras = {
        state: {
          item: item,
          data: data
        }
      };
      this.router.navigate([route], navigationExtras);
    });
  }
  saveItem(item, form, route, callback = null) {
    item.form = form;
    this.save_data(item, () => {
      if(route !== '') {
        this.router.navigate([route]);
      }

      if(typeof callback !== 'undefined' && callback != null)
      {
        callback();
      }
    });
  }
  removeItem(item, form) {
    item.form = form;
    item.removed = 1;
    this.save_data(item, () => {
    });
  }

  login(token, email, route) {
    if (this.connected) {
      console.log(this.deviceInfo);
      var user = this.getServerData({
        'controller': 'login',
        'action': 'login',
        'data': {
          'device': {
            'native': this.deviceInfo.isVirtual,
            'device_name': this.deviceInfo.model,
            'device_id': this.uuid
          },
          'token': token,
          'email': email
        }
      }, false, true).subscribe(data => {
        if (data.success == 1 && data.data.id > 0) {
          this.router.navigate([route]);
        } else {
          alert('Anmeldung nicht möglich!');
        };
      });
    } else {

    }
  }

  showLoader(){
    this.presentLoader('Lädt...');
  }

  async presentAlert(header, subHeader) {
    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      buttons: ['OK']
    });
    await alert.present();
  }

  async presentLoader(message) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: message,
      duration: 5000,
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => {});
        }
      });
    });
  }

  async dissmissLoader() {
    if (this.isLoading) {
      this.isLoading = false;
      return await this.loadingCtrl.dismiss().then(() => {}).catch(() => {});
    }
  }

  getServerData(params, loaderdismiss, silentmode = false){

    if(silentmode === false) {
      this.showLoader();
    }

    return this.doRequest(params, loaderdismiss, this.token);
  }

  doRequest(params, loaderdismiss, token): Observable<any> {

    params.uuid = this.uuid;

    params.token = this.token;

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const body = this.serialize(params, '');
    const data = this.http.post(this.APP_URL_ENDPOINT, body, { headers: headers });

    if (loaderdismiss) {
      this.dissmissLoader();
    }

    return data;
  }

  serialize = function(obj, prefix) {
    let str = [], p;
    for(p in obj) {
      if (obj.hasOwnProperty(p)) {
        let k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
        str.push((v !== null && typeof v === "object") ?
            this.serialize(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  async showSuccessToast(msg){
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2000,
      message: msg,
      cssClass: 'toast-message-cls',
      position: 'bottom',
      animated: true
    });

    await toast.present();
  }

  async showErrorToast(msg){

    const toast = await this.toastController.create({
      color: 'primary',
      duration: 2000,
      message: msg,
      cssClass: 'toast-message-cls',
      position: 'top',
      animated: true
    });

    await toast.present();
  }

  trackListByFn(index, item) {
    return item.id;
  }

  getCurrentDate() {
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }
  getCurrentDateTime() {
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    let hour = '' + d.getHours();
    if(hour.length < 2) {
      hour = '0' + hour;
    }

    return [year, month, day].join('-') + 'T' + hour + ':00';
  }
}
